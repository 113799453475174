a {
  color: white;
}

.info-map {
  position: absolute;
  background-color: #000;
  top: 50px;
  right: 30%;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;
  border-radius: 10px;
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 110%;
  margin-top: -3%;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .map-wrap {
    float: none;
    width: 100%;
    height: 700px;
    margin: 0;
  }

  .info-map {
    display: none;
  }
}
